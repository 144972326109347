
import { defineComponent, PropType } from 'vue'
import toLocale from '@/helpers/util/toLocale'

export default defineComponent({
  name: 'CollectionRecursiveList',
  props: {
    items: {
      type: Array as PropType<{ collectionId: string, title: { en: string, ja: string } }[]>,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  methods: {

  },
  setup () {
    return {
      toLocale,
    }
  },
})
