import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68f3975b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-page" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  class: "the-collection-page-body has-background-contents shadow",
  role: "main"
}
const _hoisted_4 = ["id"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "the-collection-page-collection-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecursiveListCollection = _resolveComponent("RecursiveListCollection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('collectionPage.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataList, (data, index) => {
        return (_openBlock(), _createElementBlock("section", {
          key: index,
          class: "the-collection-page-collections",
          id: 'the-collection-page-collections-' + Object.keys(data)[0]
        }, [
          (index !== 0)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_5))
            : _createCommentVNode("", true),
          _createElementVNode("header", null, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('label.' + Object.keys(data)[0])), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_RecursiveListCollection, {
              items: data[Object.keys(data)[0]],
              lang: _ctx.lang
            }, null, 8, ["items", "lang"])
          ])
        ], 8, _hoisted_4))
      }), 128))
    ])
  ]))
}