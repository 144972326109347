
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import RecursiveListCollection from '@/components/organisms/RecursiveListCollection.vue'

export default defineComponent({
  components: {
    RecursiveListCollection,
  },
  setup () {
    const store = useStore()
    const i18n = useI18n()
    const lang = i18n.locale
    const dataList = computed(() => store.getters.CollectionListForDisplay)
    return {
      dataList,
      lang,
    }
  },
})
