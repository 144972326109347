import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04d825a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-page-recursive-list" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "has-text-modest" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CollectionRecursiveList = _resolveComponent("CollectionRecursiveList")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("li", {
        key: item.collectionId,
        id: 'collection-page-recursive-list-' + item.collectionId
      }, [
        (item.searchDisplay !== 'hidden')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "collection-page-recursive-list-item",
              id: 'collection-page-recursive-list-item-'+item.collectionId
            }, [
              _createVNode(_component_router_link, {
                to: `/collections/${item.collectionId}?pageNum=0`
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(item.title[_ctx.lang]), 1),
                  _createElementVNode("span", _hoisted_4, " " + _toDisplayString(_ctx.toLocale(item.docCount, _ctx.lang)) + " " + _toDisplayString(_ctx.$t('label.case')), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ], 8, _hoisted_3))
          : _createCommentVNode("", true),
        (item.children)
          ? (_openBlock(), _createBlock(_component_CollectionRecursiveList, {
              key: 1,
              items: item.children,
              lang: _ctx.lang
            }, null, 8, ["items", "lang"]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}